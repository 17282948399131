import * as Auth from "./Auth";
import * as User from "./User";
//import * as Vetcheck from "./Vetcheck";
import * as Common from "./Common";
import * as Cart from "./Cart";
import * as Global from "./Global";

// The top-level state object
export interface ApplicationState {
  auth: Auth.AuthState | undefined;
  user: User.UserState | undefined;
  global: Global.GlobalState | undefined;
  //vetcheck: Vetcheck.VetcheckState | undefined;
  cart: Cart.CartState | undefined;
  common: Common.CommonState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  auth: Auth.reducer,
  user: User.reducer,
  cart: Cart.reducer,
  global: Global.reducer,
  common: Common.reducer,
  //vetcheck: Vetcheck.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
