import { MenuItem } from "./Footer";
const SITEURL =
  window.location.host != "app.vetxtend.co.nz"
    ? "https://vetxtend.com.au"
    : "https://vetxtend.co.nz";
/**
 * Menu links to map over when rending menus.
 */
export const menuLinks: { [key: string]: MenuItem[] } = {
  menu1: [
    {
      label: "VetXtend Plan",
      href: `/onboarding`,
    },
    {
      label: "Practice Details",
      href: `/onboarding`,
    },
    {
      label: "PMS System",
      href: `/onboarding`,
    },
    {
      label: "Finance System",
      href: `/onboarding`,
    },
    {
      label: "Banking Information",
      href: `/onboarding`,
    },
  ],
  menu2: [
    {
      label: "Rebate Statements",
      href: `/onboarding`,
    },
    {
      label: "Commission Summary",
      href: `/onboarding`,
    },
    {
      label: "Thriving Pets Members",
      href: `/onboarding`,
    },
    {
      label: "Online Sales",
      href: `/onboarding`,
    },
    {
      label: "Practice QR Code",
      href: `/onboarding`,
    },
  ],
  menu3: [
    {
      label: "My Dashboard",
      href: `/onboarding`,
    },
    {
      label: "PetPA Website",
      href: `https://yourpetpa.com.au`,
    },
    {
      label: "Thriving Pets Website",
      href: `https://thrivingpets.com.au`,
    },
  ],
};
