import "../../../styles/index.scss";
import "./App.scss";
import { Router, Switch } from "react-router-dom";
import { HelpScout, ProtectedRoute } from "../";
import { Footer } from "../../footer";
import { Header } from "../../header";
import history from "../../../utils/history";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { store } from "../../../";
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { routes } from "./routes";
import { ScrollToTop } from "../";
import usePopulateInitialData from "../../../hooks/usePopulateInitialData";
import useFacebookPixel from "../../../hooks/useFacebookPixel";
import ReactPixel from "react-facebook-pixel";
//
const App: React.FC = () => {
  const accessToken = useSelector(
    (state: ApplicationState) => state.auth?.accessToken
  );
  const [pageClass, setPageClass] = useState<string>("");
  /**
   * Fetch remote content required for this
   * application to run.
   */
  usePopulateInitialData();

  /**
   * Starts facebook pixel
   */
  useFacebookPixel();

  useEffect(() => {
    const url = window.location.href.split("/");
    const getClass = url.pop();
    if (!getClass) return;
    setPageClass(getClass);
  });
  return (
    <Router history={history}>
      <div className={`app ${pageClass}`}>
        <Header />
        <ScrollToTop>
          <Switch>
            {routes.map(({ path, Component, secured, exact }) => (
              <ProtectedRoute
                secured={secured}
                key={path}
                exact={exact}
                path={path}
                Component={Component}
              />
            ))}
          </Switch>
        </ScrollToTop>
        <Footer />
        <ToastContainer position="bottom-right" />
        <HelpScout />
      </div>
    </Router>
  );
};

export default App;
