import "./TextInput.scss";
import { FocusProps, InputProps } from "../../../types/inputs";
import { InputError, InputLabel } from "../../input";

interface TextInputProps extends InputProps, FocusProps<HTMLInputElement> {
  /**
   * The type of text input. The default is 'text'.
   */
  type?: "text" | "password" | "email" | "number";

  notes?: string;

  notesBottom?: string;

  min?: number;

  max?: number;

  placeholder?: string;
}

/**
 * Text input component with preconfigured styling.
 */
const TextInput: React.FC<TextInputProps> = ({
  label,
  notes,
  notesBottom,
  value,
  onChange,
  touched,
  error,
  type = "text",
  required,
  onFocus,
  onBlur,
  placeholder = undefined,
  disabled = false,
  min = 0,
  max = 10000,
}) => {
  value = type == "number" && max < Number(value) ? String(max) : value;
  return (
    <div className="textinput">
      <InputLabel label={label} notes={notes} required={required} />
      <input
        onFocus={onFocus}
        onBlur={onBlur}
        className="textinput__input"
        type={type}
        value={value}
        min={min}
        max={max}
        onChange={(e) => onChange(e.target.value || "")}
        disabled={disabled}
        placeholder={placeholder}
      />
      <InputError error={error} touched={touched} />
      {notesBottom && <span>{notesBottom}</span>}
    </div>
  );
};

export default TextInput;
