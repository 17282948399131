import "./Footer.scss";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { bindActionCreators } from "redux";
import { actionCreators as commonActionCreators } from "../../../store/Common";
import { Container } from "../../layout";
import logo from "../../../assets/logo.svg";
import logoFooterIcon from "../../../assets/images/thrivingpets-logo-footer.svg";
import phoneIcon from "../../../assets/images/phone.png";
import emailIcon from "../../../assets/icons/email.svg";
import homeIcon from "../../../assets/icons/home-light.svg";
import vetXtendLogoLight from "../../../assets/images/vetxtend-logo-light.svg";
import { menuLinks } from "./FooterLinks";

const YPPA_DOMAIN =
  window.location.host != "account.thrivingpets.co.nz"
    ? "yourpetpa.com.au"
    : "yourpetpa.co.nz";
export interface MenuItem {
  /**
   * The label to be displayed in the menu link.
   */
  label: string;
  /**
   * The function to be called when the menu link is clicked.
   */
  href: string;
}

export interface SocialItems {
  /**
   * The icon to be displayed.
   */
  icon: string;
  /**
   * The function to be called when the icon is clicked.
   */
  href: string;
}

/**
 * The footer of the website.
 */
const Footer: React.FC = () => {
  // const footerRef = useRef<HTMLElement>(null);
  // const [height, setHeight] = useState(0);

  // // Coming back to this to fix footer content overlap
  // useLayoutEffect(() => {
  //   if (footerRef.current) {
  //     setHeight(footerRef.current.clientHeight);
  //   }
  // }, [footerRef.current?.clientHeight]);

  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );

  const { changeActionType } = bindActionCreators(
    commonActionCreators,
    useDispatch()
  );

  if (!isAuthenticated) return null;

  const renderMenuItems = (menu: MenuItem[]) => {
    const listItems = menu.map(({ label, href }) => (
      <li>
        <a href={href}>{label}</a>
      </li>
    ));
    return <ul>{listItems}</ul>;
  };

  const renderSocialIcons = (social: SocialItems[]) => {
    const socialList = social.map(({ icon, href }) => (
      <a href={href}>
        <img className="footer__socialicons" src={icon} />
      </a>
    ));
    return <div className="footer__social">{socialList}</div>;
  };

  return (
    <footer className="footer" style={{ background: "#64b3eb" }}>
      <div className="container">
        <div style={{ padding: "40px 0 35px", borderBottom: "1px solid" }}>
          <a
            className="btn btn-dark-blue hide"
            style={{ float: "right" }}
            onClick={() => changeActionType("send-pharmacy-script")}
          >
            Send a pharmacy script
          </a>
          <span
            style={{
              fontSize: "24px",
              lineHeight: "50px",
              fontWeight: "500",
            }}
          >
            <img
              style={{ margin: "auto", display: "block" }}
              src={vetXtendLogoLight}
            />
          </span>
        </div>
        <div className="footer__row" style={{ display: "none" }}>
          <div className="footer__col">
            <h4>I want to update my:</h4>
            {renderMenuItems(menuLinks.menu1)}
          </div>
          <div className="footer__col">
            <h4>I want to update my:</h4>
            {renderMenuItems(menuLinks.menu2)}
          </div>
          <div className="footer__col">
            <h4>I want to access:</h4>
            {renderMenuItems(menuLinks.menu3)}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
