import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Store } from "redux";
import { ApplicationState } from "../store";
import { TokenResponse } from "../store/Auth";
import { toast } from "react-toastify";
import { cleanupBeforeLogout } from "../utils/auth-utils";
import { store } from "../";
const API = process.env.REACT_APP_BACKEND_API;

/**
 * It is not recommended to import the store directly as this can potentially cause
 * circular import dependency errors, this has been tested and is not an issue
 * Read More below
 * https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 */

/* Create an instance of axios */
console.log({ API });
const instance = axios.create({ baseURL: API });

/* Setup interceptor for handling refresh tokens */
instance.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const isFetchingToken = !config.url?.includes("/token");
    const existingAuthToken = config?.headers?.Authorization;
    if (isFetchingToken && !existingAuthToken) {
      config.headers = {
        Authorization: `Bearer ${store.getState().auth?.accessToken}`,
      };
    }
    return config;
  },
  (error) => {
    return error;
  }
);

/* Setup interceptor for error management */
instance.interceptors.response.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    return config;
  },
  async (error: AxiosError) => {
    const status = error.response ? error.response.status : null;
    const noRetry = ["/token", "/account"];
    // If not authorised
    if (
      (status === 401 || status === 403) &&
      error.config?.url &&
      !noRetry.includes(error.config.url)
    ) {
      // Request new access token
      const authHeader = {
        Authorization: `Bearer ${store.getState().auth?.refreshToken}`,
      };
      const response: AxiosResponse<TokenResponse> = await instance
        .post(`${API}/token`, {}, { headers: authHeader })
        .catch((responsError) => {
          return Promise.reject(responsError);
        });
      if (response && response.data && response.status === 200) {
        store.dispatch({ type: "GET_USER_TOKEN", payload: response.data });
        const renewedConfig: AxiosRequestConfig = {
          ...error.config,
          baseURL: undefined,
          headers: {
            ...error.config.headers,
            Authorization: "Bearer " + response.data.accessToken,
          },
        };
        return instance.request(renewedConfig);
      }
      // if (
      //   error?.config?.headers["Content-Type"] &&
      //   error.config.headers["Content-Type"].includes("application/json")
      // )
      //   error.config.data = JSON.parse(error.config.data); //parse the data as it is converted to string
      // return instance.request(error.config);
    } else if (
      /* If user is trying to delete their account */
      error.config?.method === "delete" &&
      error.config?.url === "/update/user" &&
      error.response?.status === 422
    ) {
      toast.error(
        "ERROR: You currently have Thriving Pet Subscriptions and/or orders within the store.\nPlease contact info@yourpetpa for assistance."
      );
      /* If user is required to log in again (e.g. password changed) */
    } else if (error.response?.status === 403) {
      (async () => {
        await cleanupBeforeLogout();
        store.dispatch({ type: "LOGOUT" });
      })();
    } else {
      return error;
    }
  }
);

export default instance;
