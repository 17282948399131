import "./Onboarding.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { sendEmailVerification } from "../../api/authorisation";
import {
  RequestPasswordReset,
  ValidateResetlink,
  ValidateAccountLink,
  SubmitNewPassword,
} from "../../api/account";
import { useFormik } from "formik";
import { SEO } from "../../components/app";
import {
  isNotEmpty,
  isEmail,
  isValidPassword,
  isEqualValue,
} from "../../utils/formik-validate";
import { Button } from "../../components/common";
import { Row, Modal } from "../../components/layout";
import { TextInput, DropdownInput } from "../../components/input";
import { ApplicationState } from "../../store";
import { actionCreators } from "../../store/Auth";
import { actionCreators as userActionCreators } from "../../store/User";
import history from "../../utils/history";
import {
  OnboardingHeader,
  OnboardingMenus,
  arrowLeftIcon,
  arrowRightIcon,
  vetXtendLogoLight,
} from "./Module";

const AccountLogin: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const recaptchaRef = useRef(null);
  const [recaptchaState, setRecaptchaState] = useState<string | null>(null);
  const [reload, setReload] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [actionValues, setActionValues] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [isValidReset, setIsValidReset] = useState(false);
  const { fetchUserToken, fetchUserData } = bindActionCreators(
    { ...actionCreators, ...userActionCreators },
    useDispatch()
  );
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const authState = useSelector((state: ApplicationState) => state.auth);
  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      (async () => {
        await fetchUserData();
        history.push("/onboarding/order-reference");
      })();
    }
    const codeId = urlParams.get("validate");
    if (codeId) {
      setIsLoading(true);
      ValidateResetlink(codeId)
        .then((r: any) => {
          setIsLoading(false);
          if (!r || r.status != 200)
            return toast.error(`invalid password reset!`);
          setIsValidReset(true);
          console.log({ codeId, d: r });
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [location, isAuthenticated]);
  const Login = () => {
    const AccountSignIn = useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      onSubmit: async (values) => {
        setIsLoading(true);
        console.log({ values });
        const { email, password } = values;
        await fetchUserToken(email, password);
        setIsLoading(false);
        //history.push("/onboarding/");
      },
      validate: async ({ email, password }) => {
        const errors = {
          ...isNotEmpty(email, "email"),
          ...isNotEmpty(password, "password"),
        };
        return errors;
      },
    });
    return (
      <>
        <h2 className="title-2">Sign In</h2>
        <Row>
          <TextInput
            label="Email Address"
            value={AccountSignIn.values.email}
            error={AccountSignIn.errors.email}
            touched={AccountSignIn.touched.email}
            onChange={AccountSignIn.handleChange("email")}
            required
          />
        </Row>
        <Row>
          <TextInput
            label="Enter Password*"
            value={AccountSignIn.values.password}
            error={AccountSignIn.errors.password}
            touched={AccountSignIn.touched.password}
            onChange={AccountSignIn.handleChange("password")}
            type="password"
            required
          />
        </Row>
        <p className="bottom-action text-center">
          <Button
            label="Login"
            isDisabled={isLoading}
            customClass="button btn btn-purple"
            onClick={async () => {
              AccountSignIn.handleSubmit();
            }}
          />
        </p>
        <p className="text-center">
          <a
            style={{ textDecoration: "underline" }}
            onClick={() => {
              setIsForgetPassword(true);
            }}
          >
            I forgot my password!
          </a>
        </p>
      </>
    );
  };
  const ForgotPassword = () => {
    const ForgotPasswordSubmit = useFormik({
      initialValues: {
        email: "",
      },
      onSubmit: async (values) => {
        setIsLoading(true);
        console.log(values.email);
        return RequestPasswordReset(values.email)
          .then((r: any) => {
            setIsLoading(false);
            console.log({ r });
            if (r.status != 200)
              return toast.error(`Request failed please try again!`);
            if (r.data.error) return toast.error(r.data.error);
            return toast.success(`Please check your email!`);
          })
          .catch(() => {
            setIsLoading(false);
          });
      },
      validate: async ({ email }) => {
        const errors = {
          ...isNotEmpty(email, "email"),
          ...isEmail(email, "email"),
        };
        return errors;
      },
    });
    return (
      <>
        <h2 className="title-2">Forget Password?</h2>
        <p>
          Enter the email address you registered and we will send you a reset
          password link!
        </p>
        <Row>
          <TextInput
            label="Email Address"
            value={ForgotPasswordSubmit.values.email}
            error={ForgotPasswordSubmit.errors.email}
            touched={ForgotPasswordSubmit.touched.email}
            onChange={ForgotPasswordSubmit.handleChange("email")}
            type="text"
            required
          />
        </Row>
        <p className="bottom-action text-center">
          <Button
            label="Send Request"
            isDisabled={isLoading}
            customClass="button btn btn-purple"
            onClick={async () => {
              ForgotPasswordSubmit.handleSubmit();
            }}
          />
        </p>
        <p className="text-center">
          <a
            style={{ textDecoration: "underline" }}
            onClick={() => {
              setIsForgetPassword(false);
            }}
          >
            I know my logins already!
          </a>
        </p>
      </>
    );
  };
  const ResetPassword = () => {
    const AccountReset = useFormik({
      initialValues: {
        password: "",
        confirmPassword: "",
      },
      onSubmit: async (values) => {
        const code = urlParams.get("validate");
        if (!code) return;
        setIsLoading(true);
        return SubmitNewPassword(code, values.password)
          .then((r) => {
            setIsLoading(false);
            if (!r || r.status != 200)
              return toast.error(`Request invalidated!`);
            toast.success(`Successfully updated password`);
            setIsForgetPassword(false);
            setIsValidReset(false);
          })
          .catch((e) => {
            setIsLoading(false);
            return toast.error(`Request error!`);
          });
      },
      validate: async ({ password, confirmPassword }) => {
        const errors = {
          ...isNotEmpty(password, "password"),
          ...isValidPassword(password, "password"),
          ...isEqualValue(
            password,
            confirmPassword,
            "confirmPassword",
            "Password not match!"
          ),
        };
        return errors;
      },
    });
    return (
      <>
        <h2 className="title-2">Enter your new Password</h2>
        <Row>
          <TextInput
            label="Enter New Password*"
            value={AccountReset.values.password}
            error={AccountReset.errors.password}
            touched={AccountReset.touched.password}
            onChange={AccountReset.handleChange("password")}
            type="password"
            required
          />
        </Row>
        <Row>
          <TextInput
            label="Confirm New Password*"
            value={AccountReset.values.confirmPassword}
            error={AccountReset.errors.confirmPassword}
            touched={AccountReset.touched.confirmPassword}
            onChange={AccountReset.handleChange("confirmPassword")}
            type="password"
            required
          />
        </Row>
        <p className="bottom-action text-center">
          <Button
            label="Change Password"
            isDisabled={isLoading}
            customClass="button btn btn-purple"
            onClick={async () => {
              AccountReset.handleSubmit();
            }}
          />
        </p>
      </>
    );
  };
  const AccountSetupRender = () => {
    return (
      <div className="content">
        <div className="steps align-center">
          <img
            src={vetXtendLogoLight}
            onClick={() => {
              setIsForgetPassword(false);
              setIsValidReset(false);
              return history.push(`/`);
            }}
            style={{ cursor: "pointer" }}
          />
          <h5>
            Don't have an account? &nbsp;
            <a
              style={{ textDecoration: "underline" }}
              onClick={() => {
                history.push("/onboarding");
              }}
            >
              Join VetXtend today!
            </a>
          </h5>
        </div>
        <div className="content-box">
          {isValidReset ? (
            <ResetPassword />
          ) : !isForgetPassword ? (
            <Login />
          ) : (
            <ForgotPassword />
          )}
        </div>
      </div>
    );
  };
  const theCaptcha = () => {
    if (!showCaptcha) return;
    return (
      <div className="captcha">
        <div className="content-box">
          <span
            className="close"
            onClick={() => {
              setShowCaptcha(false);
            }}
          >
            x
          </span>
          <h2 className="title-2">Let us know that you aren't a bot.</h2>
          <ReCAPTCHA
            sitekey="6LezQ5YUAAAAAEKPX18beuQ-z6m-mNA8AZCpa4cS"
            onChange={async (value) => {
              if (!value) return;
              const { option, values } = actionValues;
              //return console.log({ values, option });
              switch (option) {
                case "login":
                  const { email, password } = values;
                  await fetchUserToken(email, password);
                  history.push("/onboarding/dashboard");
                  break;
                default:
              }
              /*const { email, password } = AccountSignIn.values;
              await fetchUserToken(email, password);
              history.push("/onboarding/dashboard");*/
            }}
          />
        </div>
      </div>
    );
  };
  /*
   * RENDERING
   */
  return (
    <div className={isLoading ? `onboarding isloading` : `onboarding`}>
      <SEO title="Account Setup" />
      <div className="banner"></div>
      {AccountSetupRender()}
      {theCaptcha()}
    </div>
  );
};

export default AccountLogin;
