import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { GetSubscription } from "../../api/account";
import history from "../../utils/history";
import "./Onboarding.scss";
import "./ServiceAgreement.scss";
import {
  vetXtendLogoLight,
  ServiceAggreement as SERVICEAGGREEMENT,
  ServiceAggreementNZ as SERVICEAGGREEMENTNZ,
  TermsAndConditions,
} from "./Module";
import iconDownload from "../../assets/vetxtend/icons/icon-download-white.png";
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const ServiceAggreement: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const user = useSelector((state: ApplicationState) => state.user);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const [commencementDay, setCommencementDay] = useState<Date | null>(null);
  useEffect(() => {
    if (!isAuthenticated) return history.push("/onboarding/");
  }, [isAuthenticated]);
  //prettier-ignore
  useEffect(() => {
    console.log({  });
    ( async() => {
      await GetSubscription(user?.subscription_id).then((sub: any) => {
        if (!sub.data) return;
        let { current_term_start:startDate } = sub.data;
        if (!startDate)  return;
        startDate = new Date(startDate * 1000);
        //console.log(new Date(startD * 1000));
        if(!commencementDay) {
          setCommencementDay(new Date(startDate));
          window.print();
        };
      });
    })()
  }, [commencementDay]);
  //prettier-ignore
  return (
    <div className="onboarding page" id="service-agreement">
      <div
        style={{
          padding: "20px",
          width: "1000px",
          maxWidth: "100%",
          margin: "20px auto",
          background: "#fff",
        }}
      >
        {window.location.host != "app.vetxtend.co.nz" ? (
          <>
            <SERVICEAGGREEMENT user={user} />
            <TermsAndConditions />
          </>
        ) : (
          <>
            <SERVICEAGGREEMENTNZ user={user} />
            <TermsAndConditions app="NZ" />
          </>
        )}
      </div>
    </div>
  );
  //prettier-ignore end
};
export default ServiceAggreement;
