import "./Header.scss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../store/Auth";
import { actionCreators as commonActionCreators } from "../../../store/Common";
//import { actionCreators as cartActionCreators } from "../../../store/Cart";
import { actionCreators as userActionCreators } from "../../../store/User";

import Sidebar from "../../app/Sidebar/Sidebar";
import history from "../../../utils/history";
import { MenuItem } from "../../../types/menu";
import Cart from "../../shop/Cart/Cart";

import logo from "../../../assets/logo.svg";
import headsetIcon from "../../../assets/icons/headset.svg";
import petPlansIcon from "../../../assets/icons/list.svg";
import claimIcon from "../../../assets/icons/claim.svg";
import claimHistoryIcon from "../../../assets/icons/claim-history.svg";
import padlockIcon from "../../../assets/icons/padlock.svg";
import ambulanceIcon from "../../../assets/icons/ambulance.svg";
import shopIcon from "../../../assets/icons/shopHeader.svg";

import homeIcon from "../../../assets/icons/store.svg";
import chatIcon from "../../../assets/icons/chat.svg";
import editIcon from "../../../assets/icons/edit.svg";
import membershipIcon from "../../../assets/icons/membership.svg";
import paymentIcon from "../../../assets/icons/payment.svg";
import arrowLeftIcon from "../../../assets/icons/arrow-left.svg";
import listIcon from "../../../assets/icons/list.svg";
import logoutIcon from "../../../assets/icons/logout-d2.svg";
import vetXtendLogoLight from "../../../assets/images/vetxtend-logo-light.svg";
import { Modal } from "../../layout";

/**
 * The header of the website./
 */
const Header = () => {
  const { fetchUserData, fetchPractices } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  const { changeActionType } = bindActionCreators(
    commonActionCreators,
    useDispatch()
  );
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  //const cart = useSelector((state: ApplicationState) => state.cart?.cart);
  //const open = useSelector((state: ApplicationState) => state.cart?.open);
  const user = useSelector((state: ApplicationState) => state.user);
  const common = useSelector((state: ApplicationState) => state.common);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState({
    clinics: false,
  });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { logout } = bindActionCreators(actionCreators, useDispatch());
  /*const { updateCartOpen } = bindActionCreators(
    cartActionCreators,
    useDispatch()
  );*/
  const initial = () => {
    const IniTIAL = user?.practice_name?.toUpperCase().split(" ");
    const fchar = IniTIAL && IniTIAL[0] ? IniTIAL[0]?.charAt(0) : "A";
    const lchar = IniTIAL && IniTIAL[1] ? IniTIAL[1]?.charAt(0) : "Z";
    return `${fchar}${lchar}`;
  };
  const menuItems: MenuItem[] = [
    /*{
      label: `Dashboard`,
      iconUrl: homeIcon,
      onClick: () => {
        return history.push("/dashboard/");
      },
    },*/
    {
      label: `Current Plan`,
      iconUrl: paymentIcon,
      onClick: () => history.push("/onboarding/order-reference"),
    },
    {
      label: `Practices`,
      iconUrl: membershipIcon,
      onClick: () => {
        setShow({ ...show, clinics: true });
        //return history.push("/dashboard/my-practices");
      },
    },
    {
      label: `Contact Us`,
      iconUrl: chatIcon,
      onClick: () => {
        window.open("https://vetxtend.com.au/contact-us/", "_blank");
      },
    },
    {
      label: "Logout",
      iconUrl: logoutIcon,
      onClick: async () => {
        await logout();
      },
    },
  ];
  useEffect(() => {
    (async () => {
      console.log(`header reloaded`);
      await fetchPractices();
    })();
  }, [isAuthenticated]);

  const renderSidebarTrigger = () => {
    return (
      <div
        className="header__sidebartrigger"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="initialIcon">
          <span>{initial()}</span>
          <img src={arrowLeftIcon} />
        </span>
      </div>
    );
  };

  if (!isAuthenticated) return <></>;
  return (
    <header className="header">
      <div className="header__container">
        <div className="container">
          <div className="flex">
            <Link
              className="header__logo"
              style={{ display: "flex", alignItems: "center" }}
              to="/"
            >
              <img src={vetXtendLogoLight} style={{ height: "25px" }} />
            </Link>
            {renderSidebarTrigger()}
          </div>
          <Sidebar
            isOpen={sidebarOpen}
            onClose={() => setSidebarOpen(false)}
            menuItems={menuItems}
          />
        </div>
      </div>
      <Modal
        isOpen={show?.clinics ?? false}
        closeModal={() => {
          return setShow({ ...show, clinics: false });
        }}
        maxWidth={600}
        disablePadding
      >
        <div style={{ padding: "20px" }}>
          {user?.practices && (
            <>
              <p
                style={{
                  marginTop: "0",
                  fontSize: "24px",
                  textAlign: "center",
                  color: "#51429a",
                }}
              >
                Choose Default Practice <br />
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "14px",
                    letterSpacing: "1px",
                  }}
                  onClick={async () => {
                    setShow({ ...show, clinics: false });
                    return history.push("/dashboard/my-practices");
                  }}
                >
                  <img
                    src={listIcon}
                    style={{
                      width: "15px",
                      bottom: "-4px",
                      position: "relative",
                    }}
                  />
                  MANAGE PRACTICES
                </span>
              </p>
              <ul className={isLoading ? `list-block isloading` : `list-block`}>
                {user?.practices?.map((p: any) => {
                  return (
                    <li
                      className={user?.practice_id == p.id ? `active` : ``}
                      style={{
                        cursor: "default",
                        padding: "20px",
                      }}
                    >
                      <a
                        style={{ float: "right" }}
                        className="btn btn-green"
                        onClick={async () => {
                          setIsLoading(true);
                          //return console.log({ p, id: p.id });
                          if (!p.id) return;
                          await fetchUserData(p.id);
                          changeActionType(undefined);
                          setIsLoading(false);
                          setShow({ ...show, clinics: false });
                        }}
                      >
                        Set as Default
                      </a>
                      <a style={{ cursor: "default" }}>
                        <span
                          className="icon"
                          onClick={async () => {
                            setIsLoading(true);
                            //return console.log({ p, id: p.id });
                            if (!p.id) return;
                            //await fetchUserData(p.id);
                            changeActionType({
                              type: "edit-practice",
                              id: p.id,
                            });
                            setIsLoading(false);
                            setShow({ ...show, clinics: false });
                            return history.push("/dashboard/my-practices");
                          }}
                        >
                          <img src={editIcon} />
                        </span>
                        <span className="heading">{p?.name}</span>
                        <i className="location">
                          {p?.address1} {p?.address2} {p?.city} <br />
                          {p?.state} {p?.postcode} {p?.country}
                        </i>
                      </a>
                    </li>
                  );
                })}
              </ul>
              <br />
              <a
                style={{ width: "250px" }}
                className="btn btn-purple"
                onClick={async () => {
                  await changeActionType("add-new-practice");
                  setShow({ ...show, clinics: false });
                  return history.push("/onboarding/practice-information");
                }}
              >
                Subscribe New Practice
              </a>
            </>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={common?.actionType == "send-pharmacy-script" ? true : false}
        closeModal={() => changeActionType(undefined)}
        maxWidth={400}
        maxHeight={250}
        disablePadding
      >
        <div style={{ padding: "20px" }}>
          <p
            style={{
              fontSize: "24px",
              textAlign: "center",
              color: "#51429a",
            }}
          >
            This option is not yet available!
            <br />
            <br />
            <a
              className="btn btn-dark-blue"
              style={{ width: "100px" }}
              onClick={() => changeActionType(undefined)}
            >
              Close
            </a>
          </p>
        </div>
      </Modal>
    </header>
  );
};

export default Header;
