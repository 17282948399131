import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { ValidateAccountLink } from "../../api/account";
import "./Onboarding.scss";
import { SEO } from "../../components/app";
import history from "../../utils/history";
import { vetXtendLogoLight } from "./Module";
const Page404: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [context, setContext] = useState("404");
  useEffect(() => {
    const validateId = urlParams.get("validate-account");
    if (validateId) {
      setContext("validation");
      //setIsLoading(true);
      ValidateAccountLink(validateId)
        .then((r: any) => {
          console.log({ r });
          setIsLoading(false);
          if (!r || r.status != 200)
            return toast.error(`Invalid account validation!`);
          toast.success(`Successfully validated your account!`);
          history.push(`/onboarding`);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  });
  return (
    <div className={isLoading ? `onboarding isloading` : `onboarding`}>
      <SEO title="404 page" />
      <div className="banner"></div>
      <div className="content">
        <div className="steps align-center">
          <a href="/">
            <img src={vetXtendLogoLight} />
          </a>
        </div>
        <div className="content-box text-center">
          {context == "validation" ? (
            <h1>Validating...</h1>
          ) : (
            <h1>404: Page not found!</h1>
          )}
        </div>
      </div>
    </div>
  );
};
export default Page404;
