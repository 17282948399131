import axios from "../api/axios";

interface AffiliateOption {
  id: string;
  name: string;
}

export const ValidateAccountLink = async (uuid: string): Promise<any> => {
  return axios.post<any>("/account/validate-acount", { uuid });
};

export const RequestPasswordReset = async (email: string): Promise<any> => {
  const host = window.location.href.replace(window.location.pathname, "");
  return axios.post<any>("/account/request-password-reset", { email, host });
};

export const ValidateResetlink = async (code: string): Promise<any> => {
  return axios.post<any>("/account/validate-reset", { code });
};

/*export const ValidateSubscription = async (
  practice_id?: string
): Promise<any> => {
  //ret: subscription_status
  return axios.post<any>("/profile/validate-subscription", { practice_id });
};*/

export const GetSubscription = async (
  subscription_id?: string
): Promise<any> => {
  return axios.post<any>("/chargebee/retrieve-subscription", {
    subscription_id,
  });
};

export const SendQRcodeViaEmail = async (
  image_url?: string,
  link?: string
): Promise<any> => {
  //ret: subscription_status
  const host = window.location.href.replace(window.location.pathname, "");
  return axios.post<any>("/profile/email-qrcode", { image_url, link, host });
};

export const SubmitNewPassword = async (
  code: string,
  password: string
): Promise<any> => {
  const host = window.location.href.replace(window.location.pathname, "");
  return axios.post<any>("/account/submit-new-password", {
    code,
    password,
    host,
  });
};
