import "./Onboarding.scss";
import QRCode from "react-qr-code";
import { actionCreators as userActionCreators } from "../../store/User";
import { actionCreators as commonActionCreators } from "../../store/Common";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import history from "../../utils/history";
import { ApplicationState } from "../../store";
import { SendQRcodeViaEmail } from "../../api/account";
import iconDownload from "../../assets/vetxtend/icons/icon-download-white.png";
import iconEnvelope from "../../assets/vetxtend/icons/icon-envelope-white.png";
import iconLink from "../../assets/vetxtend/icons/icon-link-white.png";
import {
  checkIcon,
  homeIcon,
  shopIcon,
  YPPA_URL,
  vetXtendLogoLight,
  PharmacyText,
} from "./Module";

const SuccessOnboarding: React.FC = () => {
  const { fetchUserData } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  const { changeActionType } = bindActionCreators(
    commonActionCreators,
    useDispatch()
  );
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const user = useSelector((state: ApplicationState) => state.user);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("pending");
  const [plan, setPlan] = useState<{
    plan?: string;
    id?: string;
    status?: string;
  }>({
    plan: user?.subscription_plan,
    id: user?.subscription_id,
    status: user?.subscription_status,
  });
  const [qrValue, setQrValue] = useState(
    user?.affiliateCode
      ? window.location.host == "app.vetxtend.co.nz"
        ? `https://yourpetpa.co.nz/?ref=${user?.affiliateCode}`
        : `https://yourpetpa.com.au/?ref=${user?.affiliateCode}`
      : ""
  );
  useEffect(() => {
    if (!isAuthenticated) history.push("/onboarding");
    (async () => {
      console.log(`user reloaded!`);
      setIsLoading(true);
      await fetchUserData;
      await setPlan({
        plan: user?.subscription_plan,
        id: user?.subscription_id,
        status: user?.subscription_status,
      });
      setIsLoading(false);
      /*setIsLoading(true);
      await ValidateSubscription(user?.practice_id).then((sub: any) => {
        setIsLoading(false);
        const {
          subscription_status: ss,
          subscription_plan: sp,
          subscription_id: si,
        } = sub?.data ? sub.data : [];
        if (!sub) return;
        console.log({ ss: sub });
        //setStatus(ss);
        setPlan({ ...plan, plan: sp, status: ss, id: si });
        console.log({ plan });
        //if (sub.datasub.subscription_status != "active") return setStatus(false);
        //return setStatus(true);
      });*/
    })();
  }, [isAuthenticated, user]);

  console.log({ plan });
  const successResponse = () => {
    if (plan?.status == "pending" || !plan?.plan)
      return (
        <div className="content">
          <div className="steps align-center">
            <h2
              className="flex mb-0 align-center"
              style={{ cursor: "pointer", alignItems: "baseline" }}
              onClick={() => history.push("/")}
            >
              Welcome to
              <img src={vetXtendLogoLight} />
            </h2>
          </div>
          <div className="content-box text-center">
            <h2 style={{ color: "#505F98", fontWeight: "normal" }}>
              If you have a VetXtend account, please refresh this screen
            </h2>
            <a
              style={{ textDecoration: "underline" }}
              onClick={() => {
                return history.push("/onboarding/");
              }}
            >
              To sign up for a VetXtend account click here
            </a>
          </div>
        </div>
      );
    return (
      <div className="content">
        <div className="steps align-center">
          <h2
            className="flex mb-0 align-center"
            style={{ cursor: "pointer", alignItems: "baseline" }}
            onClick={() => history.push("/")}
          >
            Welcome to
            <img src={vetXtendLogoLight} />
          </h2>
        </div>
        <div className="content-box checkout-plan">
          <h2 className="title-2">
            Thank you for signing up to VetXtend at the {""}
            {plan?.plan == "vetxpress"
              ? "VetXpress"
              : plan?.plan == "vetxpand"
              ? "VetXpand"
              : "VetXplore"}
            {""} Partnership Level
          </h2>
          <ul className="select-a-plan">
            <li
              style={{
                border: "none",
                display: plan?.plan == "vetxpress" ? "block" : "none",
              }}
            >
              <div className="wrap order-reference">
                <div className="list-wrap" style={{ display: "none" }}>
                  <div style={{ padding: "20px" }}>
                    <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/vet-express-medium.png" />
                  </div>
                </div>
                <div className="table-wrap">
                  <table>
                    <thead>
                      <tr>
                        <td>Partnership Program & Rebates</td>
                        <td>Practice Performance Reporting</td>
                        <td>
                          Digital Marketing Service <br />
                          <span
                            style={{ fontWeight: "normal", fontSize: "16px" }}
                          >
                            (Education & Retail Communications)
                          </span>
                        </td>
                        <td>TOTAL PRICE</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                        </td>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-close.png" />
                        </td>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-close.png" />
                        </td>
                        <td>
                          <strong>$299</strong> <br />
                          plus gst annually
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </li>
            <li
              style={{
                border: "none",
                display: plan?.plan == "vetxplore" ? "block" : "none",
              }}
            >
              <div className="wrap order-reference">
                <div className="list-wrap" style={{ display: "none" }}>
                  <div style={{ padding: "20px" }}>
                    <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/vet-explore-medium.png" />
                  </div>
                </div>
                <div className="table-wrap">
                  <table>
                    <thead>
                      <tr>
                        <td>Partnership Program & Rebates</td>
                        <td>Practice Performance Reporting</td>
                        <td>
                          Digital Marketing Service <br />
                          <span
                            style={{ fontWeight: "normal", fontSize: "16px" }}
                          >
                            (Education & Retail Communications)
                          </span>
                        </td>
                        <td>TOTAL PRICE</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                        </td>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                        </td>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-close.png" />
                        </td>
                        <td>
                          <strong>$250</strong> <br />
                          plus gst per month
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </li>
            <li
              style={{
                border: "none",
                display: plan?.plan == "vetxpand" ? "block" : "none",
              }}
            >
              <div className="wrap">
                <div className="list-wrap" style={{ display: "none" }}>
                  <div style={{ padding: "20px" }}>
                    <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/vet-expand-medium.png" />
                  </div>
                </div>
                <div className="table-wrap">
                  <table>
                    <thead>
                      <tr>
                        <td>Partnership Program & Rebates</td>
                        <td>Practice Performance Reporting</td>
                        <td>
                          Digital Marketing Service <br />
                          <span
                            style={{ fontWeight: "normal", fontSize: "16px" }}
                          >
                            (Education & Retail Communications)
                          </span>
                        </td>
                        <td>TOTAL PRICE</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                        </td>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                        </td>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                        </td>
                        <td>
                          <strong>$500</strong> <br />
                          plus gst annually
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </li>
            <li style={{ border: "none" }}>
              <div style={{ margin: "20px 0 0", width: "100%" }}>
                <img
                  src={checkIcon}
                  style={{ float: "left", height: "60px" }}
                />
                <p
                  style={{
                    float: "left",
                    fontSize: "24px",
                    margin: "0 0 0 20px",
                    lineHeight: "60px",
                  }}
                >
                  Your account has been created!
                </p>
              </div>
            </li>
            <li className="download-qrcode">
              <div className="wrapper">
                <div id="qr-code" style={{ textAlign: "right" }}>
                  <QRCode value={qrValue} viewBox={`0 0 256 256`} />
                </div>
                <div className="download-bottons">
                  <p
                    className="title"
                    style={{ marginTop: "0", fontWeight: "normal" }}
                  >
                    Share this QR code or unique link with your clients and
                    start earning commission on all sales with our online store
                    and pharmacy.
                  </p>
                  <span
                    className="btn-wrapper"
                    onClick={() => {
                      const QRimg = document.getElementById("qr-code");
                      console.log({ img: QRimg?.innerHTML });
                      if (!QRimg) return;
                      const b64dc = btoa(
                        unescape(encodeURIComponent(QRimg.innerHTML))
                      );
                      const a = document.createElement("a");
                      const e = new MouseEvent("click");
                      a.download = "vetxtend-qrcode.svg";
                      a.href = "data:image/svg+xml;base64," + b64dc;
                      a.dispatchEvent(e);
                    }}
                  >
                    <span className="img-wrap">
                      <img src={iconDownload} />
                    </span>
                    Download QR Code
                  </span>
                  <span
                    className="btn-wrapper"
                    onClick={() => {
                      window
                        .open("/onboarding/service-agreement", "_blank")
                        ?.focus();
                    }}
                  >
                    <span className="img-wrap">
                      <img src={iconDownload} />
                    </span>
                    Download Agreement
                  </span>
                  <span
                    className="btn-wrapper"
                    onClick={() => {
                      const QRimg = document.getElementById("qr-code");
                      console.log({ img: QRimg?.innerHTML });
                      if (!QRimg) return;
                      const b64dc = btoa(
                        unescape(encodeURIComponent(QRimg.innerHTML))
                      );
                      return SendQRcodeViaEmail(
                        "data:image/svg+xml;base64," + b64dc,
                        qrValue
                      ).then((r: any) => {
                        toast.success(`Referral link sent!`);
                      });
                    }}
                  >
                    <span className="img-wrap">
                      <img
                        src={iconEnvelope}
                        style={{
                          width: "auto",
                          height: "auto",
                          margin: "6px 5px 5px 3px",
                        }}
                      />
                    </span>
                    Send QR Code via email
                  </span>
                  <span
                    className="btn-wrapper link"
                    onClick={() => {
                      if (qrValue.length > 0)
                        window.open(String(qrValue), "_blank");
                    }}
                  >
                    <span className="img-wrap">
                      <img
                        src={iconLink}
                        style={{
                          width: "18px",
                          height: "18px",
                          margin: "4px 5px 0px 4px",
                        }}
                      />
                    </span>
                    {qrValue}
                  </span>
                </div>
              </div>
            </li>
            <li style={{ border: "none", paddingTop: "20px" }}>
              <a
                className="btn btn-lg btn-purple"
                onClick={async () => {
                  await changeActionType("add-new-practice");
                  return history.push("/onboarding/practice-information");
                }}
              >
                Subscribe New Practice
              </a>
            </li>
            <li style={{ border: "none" }}>
              <div className="wrapper next-steps">
                <hr style={{ border: "1px solid #e3e3e3" }} />
                <br />
                <h2>Next Steps</h2>
                {plan?.plan == "vetxpress" && (
                  <>
                    <h4 className="indent">
                      <span className="round-number">1</span>
                      {`The VetXtend team will email you a proof of your practice personalised promotional materials within the next few business days. You can use these materials to help promote the PetPA online store and pharmacy. We will print and send you an initial supply and will also provide you with the digital files if you would like to print more yourself.`}
                    </h4>
                  </>
                )}
                {plan?.plan == "vetxplore" && (
                  <>
                    <h4 className="indent">
                      <span className="round-number">1</span>
                      {`The VetXtend team will email you a proof of your practice personalised promotional materials within the next few business days. You can use these materials to help promote the PetPA online store and pharmacy. We will print and send you an initial supply and will also provide you with the digital files if you would like to print more yourself.`}
                    </h4>
                    <h4 className="indent">
                      <span className="round-number">2</span>
                      {`The VetXtend CEO and Managing Director, Tomas Steenackers, will also contact you with next steps to establish your practice performance reporting.`}
                    </h4>
                  </>
                )}
                {plan?.plan == "vetxpand" && (
                  <>
                    <h4 className="indent">
                      <span className="round-number">1</span>
                      {`The VetXtend team will email you a proof of your practice personalised promotional materials within the next few business days. You can use these materials to help promote the PetPA online store and pharmacy. We will print and send you an initial supply and will also provide you with the digital files if you would like to print more yourself.`}
                    </h4>
                    <h4 className="indent">
                      <span className="round-number">2</span>
                      {`The VetXtend CEO and Managing Director, Tomas Steenackers, will also contact you with next steps to establish your practice performance reporting.`}
                    </h4>
                    <h4 className="indent">
                      <span className="round-number">3</span>
                      {`Once your dashboard reporting is underway, you will be handed over to the marketing team who will take your through and get you set up with our Digital Marketing Services as per your selected plan.`}
                    </h4>
                  </>
                )}
                <h4 style={{ marginBottom: "10px" }}>
                  In the meantime, if you have any questions or concerns, you
                  can get in touch with one of the team at {""}
                  <a
                    href={
                      window.location.host != "app.vetxtend.co.nz"
                        ? `mailto:info@vetxtend.com.au`
                        : `mailto:info@vetxtend.co.nz`
                    }
                    style={{ fontSize: "inherit" }}
                  >
                    {window.location.host != "app.vetxtend.co.nz"
                      ? `info@vetxtend.com.au`
                      : `info@vetxtend.co.nz`}
                  </a>
                </h4>
              </div>
            </li>
            <li style={{ border: "none" }}>
              <PharmacyText />
            </li>
            <li style={{ border: "none" }}>
              <div style={{ margin: "10px 0 0", width: "100%" }}>
                <hr style={{ border: "1px solid #e3e3e3" }} />
                <br />
                <span
                  style={{
                    float: "left",
                    height: "65px",
                    width: "65px",
                    padding: "10px",
                    borderRadius: "100%",
                    textAlign: "center",
                    display: "inline-block",
                    background: "#505f98",
                  }}
                >
                  <img src={homeIcon} />
                </span>
                <p
                  style={{
                    float: "left",
                    fontSize: "24px",
                    margin: "15px 0 0 20px",
                  }}
                >
                  VetXtend Partnership Portal Coming Soon.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  /*
   * RENDERING
   */
  return (
    <div className={isLoading ? "onboarding isloading" : "onboarding"}>
      <div className="banner"></div>
      {successResponse()}
    </div>
  );
};

export default SuccessOnboarding;
