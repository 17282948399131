import { toast } from "react-toastify";
import { persistor } from "../";
import { cache as ApolloCache } from "../components/app/ShopifyApolloProvider/ShopifyApolloProvider";
import { clearCheckout } from "../components/shop/Cart/Cart";
import history from "./history";

/**
 *
 */
export const cleanupBeforeLogout = async (): Promise<void> => {
  await clearCheckout();
  await persistor.purge();
  await ApolloCache.reset();
  location.reload();
  //history.push("/");
  toast.success("Successfully logged out");
};
