import axios from "../api/axios";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
const API = process.env.REACT_APP_BACKEND_API;

export interface CommonState {
  actionType: any;
}

export interface FetchPetOptionsAction {
  type: "ACTION_TYPE";
  payload: any;
}

export type KnownAction = FetchPetOptionsAction;

export const actionCreators = {
  changeActionType:
    (actionType: any): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ACTION_TYPE", payload: actionType });
    },
};

export const reducer: Reducer<any> = (state, incomingAction: Action): any => {
  const action = incomingAction as KnownAction;
  if (state === undefined) return {};
  switch (action.type) {
    case "ACTION_TYPE":
      return { ...state, actionType: action.payload };
    default:
      return state;
  }
};
