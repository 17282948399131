import * as React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { ApplicationState } from "../../../store/index";

const ProtectedRoute = ({
  path,
  exact,
  secured,
  redirectPath,
  Component,
  permission,
  render,
  ...rest
}: any): React.ReactElement => {
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated && secured) {
          return <Redirect to={{ pathname: redirectPath ?? "/login" }} />;
        }
        //if (path == "/") return <Redirect to="/dashboard" />;
        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
