import "./Dashboard.scss";
import { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { actionCreators as userActionCreators } from "../../store/User";
import { actionCreators as commonActionCreators } from "../../store/Common";
import { GetAllAffiliateCodes } from "../../api/affiliate";
import { toast } from "react-toastify";
import history from "../../utils/history";
import { useFormik } from "formik";
import {
  isNotEmpty,
  isMobile,
  isEmail,
  isAffiliateCodeExists,
  isValidBSB,
  isValidAccountNumber,
  isValidAccountNumberNZ,
} from "../../utils/formik-validate";
import { Row, Modal } from "../../components/layout";
import {
  PhoneInput,
  TextInput,
  ImageInput,
  DropdownInput,
} from "../../components/input";
import { Button } from "../../components/common";
import AddressInput from "../../components/input/AddressInput/AddressInput";

const ClinicPractices: React.FC = () => {
  const {
    fetchUserData,
    fetchPractices,
    updatePracticeInformation,
    updatePractice,
  } = bindActionCreators(userActionCreators, useDispatch());
  const { changeActionType } = bindActionCreators(
    commonActionCreators,
    useDispatch()
  );
  /*const [user, setUser] = useState(
    useSelector((state: ApplicationState) => state.user)
  );*/
  const user = useSelector((state: ApplicationState) => state.user);
  const common = useSelector((state: ApplicationState) => state.common);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const [countryCode, setCountryCode] = useState("+61");
  const [affiliateCodes, setAffiliateCodes] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [actionType, setActionType] = useState("update");
  const [coachingGroup, setCoachingGroup] = useState([
    { label: "No", value: "YPPA" },
    { label: "Accelerate Mastermind Group (AMG)", value: "AMG" },
    { label: "Crampton Consulting", value: "Crampton Consulting" },
    { label: "Lincoln Institute", value: "Lincoln" },
    { label: "Vetrr", value: "Vetrr" },
    { label: "Other", value: "YPPA" },
  ]);
  const [userAddress, setUserAddress] = useState({
    practice_name: "",
    practice_id: undefined,
    coaching_group: "YPPA",
    email: "",
    website: "",
    affiliateCode: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    province: "",
    postcode: "",
    address: user?.address,
    contact_person: "",
    contact_email: "",
    contact_firstname: "",
    contact_lastname: "",
    contact_jobtitle: "",
    subscription_plan: "",
    subscription_status: "",
    bank_account_name: "",
    bank_account_bsb: "",
    bank_account_number: "",
  });
  const BasicInformation = useFormik({
    initialValues: {
      ...userAddress,
    },
    onSubmit: async (values) => {
      console.log(`hello there`);
      const data = {
        email: values.email,
        practice_name: values.practice_name,
        practice_id: values.practice_id,
        coaching_group: values.coaching_group,
        website: values.website,
        phone: values.phone,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        country: values.country,
        postcode: values.postcode,
        contact_email: values.contact_email,
        contact_jobtitle: values.contact_jobtitle,
        contact_person: `${values.contact_firstname} ${values.contact_lastname}`,
        contact_firstname: values.contact_firstname,
        contact_lastname: values.contact_lastname,
        bank_account_name: values.bank_account_name,
        bank_account_bsb: values.bank_account_bsb,
        bank_account_number: values.bank_account_number,
      };
      //return console.log({ data });
      await setIsLoading(true);
      //return console.log({ data, actionType });
      await updatePractice(data);
      //await fetchPractices();
      //await setIsLoading(false);
      //await fetchUserData();
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsLoading(false);
    },
    validate: async ({
      practice_name,
      affiliateCode,
      email,
      phone,
      address1,
      city,
      state,
      country,
      postcode,
      contact_email,
      contact_jobtitle,
      contact_firstname,
      contact_lastname,
      bank_account_bsb,
      bank_account_name,
      bank_account_number,
    }) => {
      let errors = {
        ...isNotEmpty(practice_name, "practice_name"),
        ...isNotEmpty(affiliateCode, "affiliateCode"),
        ...isNotEmpty(email, "email"),
        ...isEmail(email, "email"),
        ...isNotEmpty(address1, "address1"),
        ...isNotEmpty(city, "city"),
        ...isNotEmpty(state, "state"),
        ...isNotEmpty(country, "country"),
        ...isNotEmpty(postcode, "postcode"),
        ...isNotEmpty(contact_firstname, "contact_firstname"),
        ...isNotEmpty(contact_lastname, "contact_lastname"),
        ...isNotEmpty(contact_email, "contact_email"),
        ...isNotEmpty(contact_jobtitle, "contact_jobtitle"),
        ...isEmail(contact_email, "contact_email"),
        ...isNotEmpty(phone, "phone"),
      };
      if (country != "AU") {
        errors = {
          ...errors,
          ...isNotEmpty(bank_account_name, "bank_account_name"),
          ...isNotEmpty(bank_account_number, "bank_account_number"),
          ...isValidAccountNumberNZ(bank_account_number, "bank_account_number"),
        };
      } else {
        errors = {
          ...errors,
          ...isNotEmpty(bank_account_name, "bank_account_name"),
          ...isNotEmpty(bank_account_bsb, "bank_account_bsb"),
          ...isValidBSB(bank_account_bsb, "bank_account_bsb"),
          ...isNotEmpty(bank_account_number, "bank_account_number"),
          ...isValidAccountNumber(bank_account_number, "bank_account_number"),
        };
      }
      /**/
      console.log({ errors });
      return errors;
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    if (!isAuthenticated) return history.push("/onboarding/");
    (async () => {
      setIsLoading(true);
      await fetchUserData();
      const code = user?.address?.country == "Australia" ? "+61" : "+64";
      setCountryCode(code);
      await GetAllAffiliateCodes().then((r: any) => {
        if (!r || (r.data && r.data.length == 0)) return;
        const data = r.data
          .reduce((g: any, c: any) => {
            const { affiliate_code: ac, affiliate_codes: acs } = c;
            if (ac) g.push(ac);
            if (acs) g.push(acs);
            return g;
          }, [])
          .filter((f: any) => {
            return f != user?.affiliateCode;
          });
        console.log({ codes: data });
        setAffiliateCodes(data);
      });
      setIsLoading(false);
      //prettier-ignore end
      console.log({ user });
    })();
  }, [isAuthenticated, location]);
  useEffect(() => {
    console.log({ actionType: common?.actionType });
    let p: any = user?.practices?.find((practice: any) => {
      return practice.id == common?.actionType?.id;
    });
    if (!p) {
      p = user;
      setUserAddress({
        ...p,
        address1: p?.address?.address1,
        address2: p?.address?.address2,
        city: p?.address?.city,
        state: p?.address?.state,
        postcode: p?.address?.postcode,
        country: p?.address?.address1 == "New Zealand" ? "NZ" : "AU",
      });
    } else {
      setUserAddress({
        ...p,
        practice_id: p?.id,
        practice_name: p?.name,
        affiliateCode: p?.affiliate_code,
        address1: p?.address,
        bank_account_bsb: p?.bank_bsb,
        bank_account_name: p?.bank_acctname,
        bank_account_number: p?.bank_acctno,
      });
    }
    console.log({ userAddress });
  }, [common?.actionType?.id, user]);
  return (
    <div className={isLoading ? `dashboard isloading` : `dashboard`}>
      <div className="section bordered-row">
        <div className="container with-sidebar">
          <div className="left-sidebar">
            <ul className="list-block">
              {user?.practices &&
                user?.practices?.map((p: any) => {
                  let isActive = false;
                  if (common?.actionType?.type == "edit-practice")
                    isActive = common?.actionType?.id == p.id ? true : false;
                  else isActive = user?.practice_id == p.id ? true : false;
                  return (
                    <li
                      className={isActive ? `active` : ``}
                      onClick={(e) => {
                        changeActionType({
                          type: "edit-practice",
                          id: p.id,
                        });
                      }}
                    >
                      <a>
                        <span className="heading">{p?.name}</span>
                        <i className="location">
                          {p?.address1} {p?.address2} {p?.city} {p?.state}
                          {""} {p?.postcode}
                        </i>
                      </a>
                    </li>
                  );
                })}
            </ul>
            <br />
            <a
              style={{ width: "auto", padding: "10px" }}
              className="btn btn-purple"
              onClick={async (e: any) => {
                await changeActionType("add-new-practice");
                return history.push("/onboarding/practice-information");
              }}
            >
              Subscribe New Practice
            </a>
          </div>
          <div className="content">
            {actionType == "update" &&
              user?.practice_id != userAddress.practice_id && (
                <a
                  style={{ float: "right" }}
                  className="btn btn-purple"
                  onClick={async () => {
                    /*setIsLoading(true);
                    await fetchUserData(user?.practice_id);
                    setUserAddress({
                      ...userAddress,
                      practice_id: user?.practice_id ? user?.practice_id : "",
                    });
                    changeActionType(undefined);
                    await setIsLoading(false);
                    //return console.log({ pid: user?.practice_id });*/
                  }}
                >
                  Set as Default
                </a>
              )}
            <h3 className="header-title-2">Practice Information</h3>
            {userAddress?.subscription_plan &&
              userAddress?.subscription_status != "pending" && (
                <div className="subscription-section">
                  <span>
                    Plan Type: {""}
                    <strong style={{ textTransform: "uppercase" }}>
                      {userAddress?.subscription_plan}
                    </strong>
                  </span>
                </div>
              )}
            <Row>
              <TextInput
                label="Practice Name"
                value={BasicInformation.values.practice_name}
                error={BasicInformation.errors.practice_name}
                touched={BasicInformation.touched.practice_name}
                onChange={BasicInformation.handleChange("practice_name")}
                required
              />
            </Row>
            <Row>
              <DropdownInput
                label="Is your practice part of a coaching group?"
                required={true}
                options={coachingGroup}
                error={BasicInformation.errors.coaching_group}
                touched={BasicInformation.touched.coaching_group}
                onChange={BasicInformation.handleChange("coaching_group")}
                value={BasicInformation.values.coaching_group}
              />
              <TextInput
                label="Website"
                value={BasicInformation.values.website}
                error={BasicInformation.errors.website}
                touched={BasicInformation.touched.website}
                onChange={BasicInformation.handleChange("website")}
              />
            </Row>
            <Row>
              <TextInput
                label="Email"
                value={BasicInformation.values.email}
                error={BasicInformation.errors.email}
                touched={BasicInformation.touched.email}
                onChange={BasicInformation.handleChange("email")}
                required
              />
              <TextInput
                label="Phone Number"
                value={BasicInformation.values.phone}
                error={BasicInformation.errors.phone}
                touched={BasicInformation.touched.phone}
                onChange={BasicInformation.handleChange("phone")}
                required
              />
            </Row>
            <br />
            <h3 className="header-title-2">Address</h3>
            <Row>
              <AddressInput form={BasicInformation} />
            </Row>
            <br />
            <h3 className="header-title-2">Contact</h3>
            <Row>
              <TextInput
                label="First name"
                value={BasicInformation.values.contact_firstname}
                error={BasicInformation.errors.contact_firstname}
                touched={BasicInformation.touched.contact_firstname}
                onChange={BasicInformation.handleChange("contact_firstname")}
                required
              />
              <TextInput
                label="Last name"
                value={BasicInformation.values.contact_lastname}
                error={BasicInformation.errors.contact_lastname}
                touched={BasicInformation.touched.contact_lastname}
                onChange={BasicInformation.handleChange("contact_lastname")}
                required
              />
            </Row>
            <Row>
              <TextInput
                label="Job Title"
                value={BasicInformation.values.contact_jobtitle}
                error={BasicInformation.errors.contact_jobtitle}
                touched={BasicInformation.touched.contact_jobtitle}
                onChange={BasicInformation.handleChange("contact_jobtitle")}
                required
              />
              <TextInput
                label="Email"
                value={BasicInformation.values.contact_email}
                error={BasicInformation.errors.contact_email}
                touched={BasicInformation.touched.contact_email}
                onChange={BasicInformation.handleChange("contact_email")}
                required
              />
            </Row>
            <br />
            <h3 className="header-title-2">BANK INFORMATION</h3>
            <Row>
              <TextInput
                label="Bank Account Name"
                value={BasicInformation.values.bank_account_name}
                error={BasicInformation.errors.bank_account_name}
                touched={BasicInformation.touched.bank_account_name}
                onChange={BasicInformation.handleChange("bank_account_name")}
                required
              />
            </Row>
            <Row>
              {userAddress?.country == "AU" && (
                <TextInput
                  label="BSB"
                  value={BasicInformation.values.bank_account_bsb}
                  error={BasicInformation.errors.bank_account_bsb}
                  touched={BasicInformation.touched.bank_account_bsb}
                  onChange={BasicInformation.handleChange("bank_account_bsb")}
                  required
                />
              )}
              <TextInput
                label="Bank Account Number"
                value={BasicInformation.values.bank_account_number}
                error={BasicInformation.errors.bank_account_number}
                touched={BasicInformation.touched.bank_account_number}
                onChange={BasicInformation.handleChange("bank_account_number")}
                required
              />
            </Row>
            <p
              style={{ width: "700px", maxWidth: "100%" }}
              className="bottom-action text-center flex"
            >
              <a
                className="btn btn-purple"
                onClick={() => {
                  BasicInformation.handleSubmit();
                }}
              >
                Update Practice
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClinicPractices;
