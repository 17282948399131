import React from "react";
import { Helmet } from "react-helmet";

interface SEOProps {
  title?: string;
  description?: string;
}

/**
 * Component used to pass metadata into the html head.
 */
const SEO: React.FC<SEOProps> = ({
  /**
   * The title to be rendered in the browser tab.
   */
  title = "Your PetPA - Pet Education in Your Pocket",
}) => {
  return (
    <Helmet>
      <title>{`VetXtend App | ${title}`}</title>]
    </Helmet>
  );
};

export default SEO;
